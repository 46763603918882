import { inject, Injectable } from '@angular/core';
import { ApiService } from '@features/api';

import { Country } from '../models';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  protected api = inject(ApiService);

  list() {
    return this.api.get<Country[]>('/countries');
  }
}
