import { Component, DestroyRef, inject, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';

import { Recover } from '../../models';
import { AuthService } from '../../services';

export interface RecoverForm {
  email: FormControl<Recover['email']>;
}

@Component({
  selector: 'app-recover-page',
  templateUrl: './recover-page.component.html',
  styleUrls: ['./recover-page.component.scss'],
})
export class RecoverPageComponent {
  #builder = inject(FormBuilder);
  #destroyed = inject(DestroyRef);
  #auth = inject(AuthService);

  protected error = signal<string | null>(null);
  protected message = signal<string | null>(null);
  protected loading = signal<boolean>(false);

  protected form: FormGroup<RecoverForm> = this.#builder.nonNullable.group({
    email: this.#builder.nonNullable.control('', [
      Validators.required,
      Validators.email,
    ]),
  });

  recover() {
    const payload = this.form.getRawValue();

    this.loading.set(true);
    this.error.set(null);
    this.message.set(null);

    this.#auth
      .recover(payload)
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: ({ message }) => this.message.set(message),
        error: ({ message }: { message: string }) => this.error.set(message),
      });
  }
}
