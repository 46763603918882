<form id="recover-form" class="form" [formGroup]="form" (ngSubmit)="recover()">
  <legend>
    <h1>{{ "recover.title" | translate }}</h1>
    <p>{{ "recover.subtitle" | translate }}</p>
  </legend>

  @if (error(); as error) {
    <p class="message error">
      {{ error }}
    </p>
  }

  @if (message(); as message) {
    <p class="message success">
      {{ message }}
    </p>
  }

  <fieldset class="controls" [disabled]="loading()">
    <div class="field">
      <label for="email-input" class="label">
        {{ "recover.form.email.label" | translate }}
      </label>
      <input
        id="email-input"
        type="email"
        class="input"
        placeholder="{{ 'recover.form.email.placeholder' | translate }}"
        formControlName="email"
      />
      <app-form-error
        [control]="form.get('email')"
        [messages]="{
          required: 'register.form.email.errors.required' | translate,
          invalid: 'register.form.email.errors.invalid' | translate,
        }"
      ></app-form-error>
    </div>

    <input
      type="submit"
      app-filled-button
      theme="primary"
      value="{{
        (loading()
          ? 'recover.form.actions.submit.loading'
          : 'recover.form.actions.submit.idle'
        ) | translate
      }}"
      [disabled]="form.invalid"
    />
  </fieldset>
</form>
