import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideRouter, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { catchError, firstValueFrom, of } from 'rxjs';

import { FilledButtonComponent } from '@ui/components/button';
import { FormErrorComponent } from '@ui/components/form-error';
import { L10nModule } from '@features/l10n';

import { routes } from './auth.routes';
import { AuthService, JwtService } from './services';
import {
  LoginPageComponent,
  RecoverPageComponent,
  RegisterPageComponent,
  ResetPageComponent,
} from './pages';

@NgModule({
  declarations: [
    LoginPageComponent,
    RegisterPageComponent,
    RecoverPageComponent,
    ResetPageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FilledButtonComponent,
    RouterModule,
    L10nModule,
    FormErrorComponent,
  ],
  exports: [],
  providers: [
    AuthService,
    JwtService,
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService, jwt: JwtService) => () => {
        return jwt.token
          ? firstValueFrom(auth.populate().pipe(catchError(() => of(null))))
          : Promise.resolve();
      },
      deps: [AuthService, JwtService],
      multi: true,
    },
    provideRouter(routes),
  ],
})
export class AuthModule {}
