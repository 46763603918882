<form id="login-form" class="form" [formGroup]="form" (ngSubmit)="login()">
  <legend>
    <h1>{{ 'login.title' | translate }}</h1>
    <p>{{ 'login.subtitle' | translate }}</p>
  </legend>

  @if(error(); as error){
  <p class="message error">
    {{ error }}
  </p>
  }

  <fieldset class="controls" [disabled]="loading()">
    <div class="field">
      <label for="email-input" class="label">
        {{ 'login.form.email.label' | translate }}
      </label>
      <input id="email-input" type="email" class="input" placeholder="{{ 'login.form.email.placeholder' | translate }}"
        formControlName="email" />

      <app-form-error [control]="form.get('email')" [messages]="{
        'email' : 'login.form.email.errors.invalid' | translate,
        'required': 'login.form.email.errors.required' | translate
      }"></app-form-error>
    </div>

    <div class="field">
      <label for="password-input" class="label">
        {{ 'login.form.password.label' | translate }}
      </label>
      <input id="password-input" type="password" class="input"
        placeholder="{{ 'login.form.password.placeholder' | translate }}" formControlName="password" />

      <app-form-error [control]="form.get('password')" [messages]="{
          'email' : 'login.form.password.errors.invalid' | translate,
          'required': 'login.form.password.errors.required' | translate
        }"></app-form-error>
    </div>

    <div>
      <a class="link-text" [routerLink]="['/recover']">
        {{ 'login.links.recover.title' | translate }}
      </a>
    </div>

    <input type="submit" app-filled-button theme="primary"
      value="{{ 'login.form.actions.submit.' + (loading() ? 'loading' : 'idle') | translate }}"
      [disabled]="form.invalid" />
  </fieldset>

  <p>{{ 'login.links.register.prefix' | translate }}&nbsp;
    <a class="link-text" [routerLink]="['/register']">
      {{ 'login.links.register.title' | translate }}
    </a>
  </p>
</form>