import { Route } from '@angular/router';

import {
  LoginPageComponent,
  RecoverPageComponent,
  RegisterPageComponent,
  ResetPageComponent,
} from './pages';
import { noAuthGuard } from './guards';

export const routes: Route[] = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'reset',
    component: ResetPageComponent,
    canActivate: [noAuthGuard],
  },
  {
    path: 'recover',
    component: RecoverPageComponent,
    canActivate: [noAuthGuard],
  },
];
