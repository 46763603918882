import {
  HttpInterceptorFn,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';

import { API_CONFIG, ApiConfig } from './api-config';
import { ApiService } from './api.service';
import { errorInterceptor } from './interceptors';

export const provideApi = (
  config: ApiConfig,
  interceptors: HttpInterceptorFn[] = [],
) => {
  return makeEnvironmentProviders([
    provideHttpClient(withInterceptors([errorInterceptor, ...interceptors])),
    { provide: API_CONFIG, useValue: config },

    ApiService,
  ]);
};
