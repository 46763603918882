import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControlName,
  FormGroupDirective,
} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class FormErrorComponent {
  control = input.required<AbstractControl | null>();
  messages = input.required<Record<string, string>>();
}
