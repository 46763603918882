import { computed, Injectable } from '@angular/core';

@Injectable()
export class JwtService {
  get token(): string | null {
    return localStorage.getItem('token');
  }

  save(token: string): void {
    localStorage.setItem('token', token);
  }

  destroy(): void {
    localStorage.removeItem('token');
  }
}
