import { Component, DestroyRef, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { exhaustMap, finalize, from } from 'rxjs';

import { Login } from '../../models';
import { AuthService } from '../../services';

export interface LoginForm {
  email: FormControl<Login['email']>;
  password: FormControl<Login['password']>;
}

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  #builder = inject(FormBuilder);
  #destroyed = inject(DestroyRef);
  #router = inject(Router);
  #auth = inject(AuthService);

  protected error = signal<string | null>(null);
  protected loading = signal<boolean>(false);

  protected form: FormGroup<LoginForm> =
    this.#builder.nonNullable.group<LoginForm>({
      email: this.#builder.nonNullable.control<string>('', [
        Validators.required,
        Validators.email,
      ]),
      password: this.#builder.nonNullable.control<string>(
        '',
        Validators.required,
      ),
    });

  login() {
    const payload = this.form.getRawValue();

    this.loading.set(true);
    this.error.set(null);

    this.#auth
      .login(payload)
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        exhaustMap(() => from(this.#router.navigate(['/']))),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        error: ({ message }: { message: string }) => this.error.set(message),
      });
  }
}
