import { Component, DestroyRef, inject, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CountryService } from '@features/countries';

import { Register } from '../../models';
import { AuthService } from '../../services';
import { finalize } from 'rxjs';
import { CustomValidators } from '@ui/validation';

export interface RegisterForm {
  first_name: FormControl<Register['first_name']>;
  last_name: FormControl<Register['last_name']>;
  email: FormControl<Register['email']>;
  username: FormControl<Register['username']>;
  date_of_birth: FormControl<Register['date_of_birth']>;
  address_country_id: FormControl<Register['address_country_id']>;
  password: FormControl<Register['password']>;
  password_confirmation: FormControl<Register['password_confirmation']>;
  terms: FormControl<Register['terms']>;
  correct_details: FormControl<Register['correct_details']>;
  only_one_registration: FormControl<Register['only_one_registration']>;
}

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent {
  #builder = inject(FormBuilder);
  #destroyed = inject(DestroyRef);
  #countries = inject(CountryService);
  #auth = inject(AuthService);

  protected error = signal<string | null>(null);
  protected message = signal<string | null>(null);
  protected loading = signal<boolean>(false);

  protected form: FormGroup<RegisterForm> = this.#builder.nonNullable.group({
    first_name: this.#builder.nonNullable.control('', [Validators.required]),
    last_name: this.#builder.nonNullable.control('', [Validators.required]),
    email: this.#builder.nonNullable.control('', [
      Validators.required,
      Validators.email,
    ]),
    username: this.#builder.nonNullable.control('', [Validators.required]),
    date_of_birth: this.#builder.nonNullable.control('', [Validators.required]),
    address_country_id: this.#builder.nonNullable.control('', [
      Validators.required,
    ]),
    password: this.#builder.nonNullable.control('', CustomValidators.password),
    password_confirmation: this.#builder.nonNullable.control('', [
      Validators.required,
      CustomValidators.sameAs('password'),
    ]),
    terms: this.#builder.nonNullable.control(false, [Validators.requiredTrue]),
    correct_details: this.#builder.nonNullable.control(false, [
      Validators.requiredTrue,
    ]),
    only_one_registration: this.#builder.nonNullable.control(false, [
      Validators.requiredTrue,
    ]),
  });

  protected countries = toSignal(this.#countries.list(), { initialValue: [] });

  register() {
    const payload = this.form.getRawValue();

    this.loading.set(true);
    this.error.set(null);

    this.#auth
      .register(payload)
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: ({ message }) => this.message.set(message),
        error: ({ message }: { message: string }) => this.error.set(message),
      });
  }
}
