<form id="reset-form" class="form" [formGroup]="form">
  <legend>
    <h1>{{ 'reset.title' | translate }}</h1>
    <p>{{ 'reset.subtitle' | translate }}</p>
  </legend>

  @if(error(); as error){
  <p class="message error">
    {{ error }}
  </p>
  }

  @if(message(); as message){
  <p class="message success">
    {{ message }}
  </p>
  }

  <fieldset class="controls" [disabled]="loading()">
    <div class="field">
      <label for="password-input" class="label">
        {{ 'reset.form.password.label' | translate }}
      </label>
      <input id="password-input" type="password" class="input" autocomplete="new-password"
        placeholder="{{ 'reset.form.password.placeholder' | translate }}" formControlName="new_password" />

      <app-form-error [control]="form.get('password')" [messages]="{
        'required': 'register.form.password.errors.required' | translate,
        'uppercase': 'register.form.password.errors.uppercase' | translate,
        'lowercase': 'register.form.password.errors.lowercase' | translate,
        'digit': 'register.form.password.errors.digit' | translate,
        'special': 'register.form.password.errors.special' | translate,
        'minlength': 'register.form.password.errors.minlength' | translate,
      }"></app-form-error>
    </div>

    <div class="field">
      <label for="confirm-password-input" class="label">
        {{ 'reset.form.confirm-password.label' | translate }}
      </label>
      <input id="confirm-password-input" type="password" class="input" autocomplete="new-password"
        placeholder="{{ 'reset.form.confirm-password.placeholder' | translate }}"
        formControlName="new_password_confirmation" />

      <app-form-error [control]="form.get('confirm_password')" [messages]="{
        'required': 'register.form.confirm-password.errors.required' | translate,
        'match': 'register.form.confirm-password.errors.match' | translate,
      }"></app-form-error>
    </div>

    <input type="submit" app-filled-button theme="primary"
      value="{{ (loading() ? 'reset.form.actions.submit.loading' : 'reset.form.actions.submit.idle') | translate }}"
      [disabled]="form.invalid || loading()" />
  </fieldset>
</form>