import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { CustomValidators } from '@ui/validation';

import { Reset } from '../../models';
import { AuthService } from '../../services';

export interface ResetForm {
  code: FormControl<Reset['code']>;
  new_password: FormControl<Reset['new_password']>;
  new_password_confirmation: FormControl<Reset['new_password_confirmation']>;
}

@Component({
  selector: 'app-reset-page',
  templateUrl: './reset-page.component.html',
  styleUrls: ['./reset-page.component.scss'],
})
export class ResetPageComponent {
  #builder = inject(FormBuilder);
  #destroyed = inject(DestroyRef);
  #auth = inject(AuthService);

  protected route = inject(ActivatedRoute);

  protected error = signal<string | null>(null);
  protected message = signal<string | null>(null);
  protected loading = signal<boolean>(false);

  protected form: FormGroup<ResetForm> = this.#builder.nonNullable.group({
    code: this.#builder.nonNullable.control(
      this.route.snapshot.queryParams['code'],
      Validators.required,
    ),
    new_password: this.#builder.nonNullable.control(
      '',
      CustomValidators.password,
    ),
    new_password_confirmation: this.#builder.nonNullable.control('', [
      Validators.required,
      CustomValidators.sameAs('new_password'),
    ]),
  });

  reset() {
    const payload = this.form.getRawValue();

    this.loading.set(true);
    this.error.set(null);
    this.message.set(null);

    this.#auth
      .reset(payload)
      .pipe(
        takeUntilDestroyed(this.#destroyed),
        finalize(() => this.loading.set(false)),
      )
      .subscribe({
        next: ({ message }) => this.message.set(message),
        error: ({ message }: { message: string }) => this.error.set(message),
      });
  }
}
